// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/fire-burns-up/currentstudios-dev/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-jsx": () => import("/builds/fire-burns-up/currentstudios-dev/src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-project-jsx": () => import("/builds/fire-burns-up/currentstudios-dev/src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-tag-collection-jsx": () => import("/builds/fire-burns-up/currentstudios-dev/src/templates/TagCollection.jsx" /* webpackChunkName: "component---src-templates-tag-collection-jsx" */),
  "component---src-templates-tag-all-projects-jsx": () => import("/builds/fire-burns-up/currentstudios-dev/src/templates/TagAllProjects.jsx" /* webpackChunkName: "component---src-templates-tag-all-projects-jsx" */),
  "component---src-pages-index-jsx": () => import("/builds/fire-burns-up/currentstudios-dev/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

