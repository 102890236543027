module.exports = [{
      plugin: require('/builds/fire-burns-up/currentstudios-dev/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/fire-burns-up/currentstudios-dev/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('/builds/fire-burns-up/currentstudios-dev/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WT4S3WL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby-currentstudios"}}},
    },{
      plugin: require('/builds/fire-burns-up/currentstudios-dev/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/fire-burns-up/currentstudios-dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
